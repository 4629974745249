// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/polling/Polling.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/polling/Polling.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, HStack, Heading, IconButton, Input, InputGroup, InputRightAddon, ListItem, OrderedList, Progress, Radio, RadioGroup, SkeletonCircle, SkeletonText, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import moment from "moment-timezone";
import { FaCheckCircle, FaClock, FaCrown } from "react-icons/fa";
import { isEditor, useAuth } from "~/utils/userUtils";
import { GermanNumber } from "~/utils/MeiliSearch";
import { AddIcon } from "@chakra-ui/icons";
export const UmfrageBox = _s(forwardRef(_c = _s((props, ref) => {
  _s();
  const {
    umfrageId
  } = props;
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const {
    user,
    checkUsershowLogin
  } = useAuth();
  const [error, setError] = useState();
  const [voteAfterLogin, setVoteAfterLogin] = useState();
  async function fetchData() {
    setLoading(true);
    await fetch(`/api/polling/id/${umfrageId}`, {
      headers: {
        "Content-Type": "application/json" // Add this line
      }
    }).then(res => res.json()).then(data => {
      const pollingData = {
        id: data.data.id,
        ...data.data.attributes
      };
      setPolling(pollingData);
      setShowResult(moment(pollingData.endDate).isBefore(moment()) || pollingData.yourChoices.length > 0);
    }).catch(console.error);
    setLoading(false);
  }
  async function vote(polling_entry, pollingId) {
    if (!user) {
      setVoteAfterLogin({
        polling_entry,
        pollingId
      });
      checkUsershowLogin();
      return;
    }
    if (polling.startDate && polling.endDate && !moment().isBetween(moment(polling.startDate), moment(polling.endDate))) {
      return;
    }
    setLoading(true);
    setError(undefined);
    await fetch(`/api/polling/vote`, {
      headers: {
        "Content-Type": "application/json" // Add this line
      },

      method: "post",
      body: JSON.stringify({
        polling: pollingId,
        polling_entry
      })
    }).then(async res => {
      const response = await res.json();
      if (res.status != 200) {
        setError(response);
      }
      return response;
    }).then(data => {
      // console.log(data);
      fetchData();
    }).catch(e => {
      console.error(e);
    });
    setLoading(false);
  }
  async function notification(pollingId) {
    if (!user) {
      checkUsershowLogin();
      return;
    }
    setLoading(true);
    setError(undefined);
    await fetch(`/api/polling/notification`, {
      headers: {
        "Content-Type": "application/json" // Add this line
      },

      method: "post",
      body: JSON.stringify({
        polling: pollingId
      })
    }).then(async res => {
      const response = await res.json();
      if (res.status != 200) {
        setError(response);
      }
      return response;
    }).then(data => {
      // console.log(data);
      fetchData();
    }).catch(e => {
      console.error(e);
    });
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (user && voteAfterLogin) {
      vote(voteAfterLogin.polling_entry, voteAfterLogin.pollingId).then(result => {
        setVoteAfterLogin(undefined);
      });
    }
  }, [user]);
  const bgColor = useColorModeValue("gray.200", "gray.700");
  return <Box p={4}>
      {!polling && <Box padding="6" boxShadow="lg">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>}
      {polling && <Box rounded={10} background={bgColor} p={5}>
          <Heading size={"md"} mb={3}>
            {polling.name}
          </Heading>
          {polling.startDate && moment(polling.startDate).isAfter(moment()) && <Box fontSize={"lg"} mb={3}>
              Umfrage startet {moment(polling.startDate).fromNow()}
            </Box>}
          {error && <Alert status="error" mb={3}>
              <AlertIcon />
              <AlertTitle>Fehler beim Speichern:</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
          {<VStack alignItems={"stretch"} mb={3}>
              {polling.polling_entries.data.map((poll, index) => {
          const val = poll.attributes.users > 0 ? poll.attributes.users / polling.sum * 100 : 0;
          const choice = polling.yourChoices.find(p => p == poll.id);
          return <Box key={"poll-" + poll.id} w={"100%"}>
                    {showResult && <HStack pos={"relative"}>
                        <Progress hasStripe value={val} w={"90%"} height={"26px"} colorScheme={poll.attributes.winner ? "pink" : choice ? "green" : "blue"} />
                        <Box fontWeight={poll.attributes.winner ? "black" : "normal"}>
                          <GermanNumber value={val} decimal={1} content="%" />
                        </Box>
                        <HStack pos={"absolute"} left={3} top={0} bottom={0} fontWeight={poll.attributes.winner ? "black" : "normal"}>
                          <Box>{poll.attributes.poll}</Box>
                          {poll.attributes.winner && <FaCrown />}
                        </HStack>
                      </HStack>}
                    {!showResult && <Button w={"100%"} onClick={() => {
              vote(poll.id, polling.id);
            }} isDisabled={polling.startDate && polling.endDate && !moment().isBetween(moment(polling.startDate), moment(polling.endDate))} isLoading={loading}>
                        {poll.attributes.poll}
                      </Button>}
                  </Box>;
        })}
              <Box fontSize={"sm"} mt={1}>
                {polling.sum} Stimme{polling.sum != 1 && "n"}
                {moment(polling.endDate).isBefore(moment()) && " - Endergebnisse"}
              </Box>
            </VStack>}
          <Box fontSize={"sm"} mb={3} fontStyle={"italic"}>
            {polling.content}
          </Box>
          {polling.competition && <Box fontSize={"sm"}>
              <Heading size={"md"}>Gewinner</Heading>
              {polling.winnerUsers.data && <OrderedList ml={1}>
                  {polling.winnerUsers.data.map((winner, index) => <ListItem key={"winner-" + index} alignItems={"start"}>
                        <HStack>
                          <Text>
                            {winner.username}
                            {winner.email && " / " + winner.email + " / " + winner.id + " / " + (winner.confirmFound ? "Bestätigt" : "Unbestätigt")}
                          </Text>
                          {winner.confirmFound ? <FaCheckCircle color="green" /> : <FaClock color="yellow" />}
                        </HStack>
                      </ListItem>)}
                </OrderedList>}
              {isEditor(user) && polling.winnerUsers.data && <HStack>
                  <Button onClick={() => {
            notification(polling.id);
          }}>
                    Gewinner benachrichtigen
                  </Button>

                  {polling.winnerNotification && <Text>
                    Versendet{" "}
                    {moment(polling.winnerNotification).format("DD.MM.YYYY u\\m HH:mm")}
                  </Text>}
                </HStack>}
              {!polling?.winnerUsers?.data?.length && moment(polling.endDate).isAfter(moment()) && <Box>
                    Sobald das Gewinnspiel vorbei ist, geben wir die Gewinner
                    bekannt. Es endet {moment(polling.endDate).fromNow()}, also
                    haltet euch bereit – am{" "}
                    {moment(polling.endDate).format("DD.MM.YYYY u\\m HH:mm")}{" "}
                    Uhr ist Schluss!
                  </Box>}
              {!polling?.winnerUsers?.data?.length && moment(polling.winnerDate).isAfter(moment()) && <Box>
                    Bekanntgabe der Gewinner:{" "}
                    {moment(polling.winnerDate).format("DD.MM.YYYY u\\m HH:mm")}{" "}
                    Uhr
                  </Box>}
            </Box>}
        </Box>}
    </Box>;
}, "wjv8JOWVY2BTAJB4h1zR7XIGxhU=", false, function () {
  return [useAuth, useColorModeValue];
})), "wjv8JOWVY2BTAJB4h1zR7XIGxhU=", false, function () {
  return [useAuth, useColorModeValue];
});
_c2 = UmfrageBox;
export const PollControl = _s2(forwardRef(_c3 = _s2((props, ref) => {
  _s2();
  const [winner, setWinner] = useState("");
  const [formik, setFormik] = useState(props.formik);
  const [polls, setPolls] = useState(props.formik?.values?.polling_entries?.data?.length > 0 && props.formik?.values?.polling_entries?.data?.map(pe => ({
    poll: pe.attributes.poll,
    id: pe.id
  })) || Array.isArray(props.formik?.values?.polling_entries) && props.formik?.values?.polling_entries || [{
    poll: ""
  }]);
  function changePoll(text, index) {
    const tmpPolls = polls;
    if (index < tmpPolls.length) {
      tmpPolls[index].poll = text;
    }
    setPolls([...tmpPolls]);
  }
  function addPoll() {
    !formik?.values.id && setPolls([...polls, {
      poll: ""
    }]);
  }
  useImperativeHandle(ref, () => ({
    setFormikObj: formik => {
      setFormik(formik);
    }
  }));
  useEffect(() => {
    if (formik) {
      // console.log(formik.values)
      if (typeof props.formik?.values.winner == "string") {
        setWinner(props.formik?.values.winner);
      } else if (props.formik?.values?.winner?.data) {
        props.formik?.values?.polling_entries?.data?.map((pe, index) => {
          pe.id == props.formik?.values.winner.data.id && setWinner(String(index));
        });
      }
    }
  }, [formik]);
  useEffect(() => {
    if (formik) {
      formik.setFieldValue("polling_entries", polls);
      formik.setFieldValue("winner", winner);
    }
  }, [polls, winner]);
  return <VStack spacing={2} alignItems={"start"}>
      <Box mt={5}>
        <Heading size={"sm"}>Einträge</Heading>
      </Box>
      <RadioGroup onChange={setWinner} value={winner} w={"100%"} name="winner">
        {polls.map((poll, index) => <HStack key={"poll_" + index} w={"100%"} mb={2}>
            <InputGroup>
              <Input type="text" name={"poll_" + index} placeholder="Eintrag" value={poll.poll} onChange={e => changePoll(e.currentTarget.value, index)} onKeyUp={event => {
            if (!formik?.values.id && event.key === "Enter") {
              addPoll();
            }
          }} />
              <InputRightAddon>
                <Radio size="md" value={String(index)} colorScheme="blue">
                  <FaCrown />
                </Radio>
              </InputRightAddon>
            </InputGroup>
            {!formik?.values.id && polls.length == index + 1 && <IconButton aria-label="Add Entry" icon={<AddIcon />} onClick={() => {
          addPoll();
        }} />}
          </HStack>)}
      </RadioGroup>
    </VStack>;
}, "IabemUiC2iLhwStAMgtse1lDq+A=")), "IabemUiC2iLhwStAMgtse1lDq+A=");
_c4 = PollControl;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "UmfrageBox$forwardRef");
$RefreshReg$(_c2, "UmfrageBox");
$RefreshReg$(_c3, "PollControl$forwardRef");
$RefreshReg$(_c4, "PollControl");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;