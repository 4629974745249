// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/settingUtils.tsx"
);
import.meta.hot.lastModified = "1708335860448.164";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { userPrefs } from "~/cookies.server";
import { getLocalStorage, storageAvailable } from "./fetchUtils";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { Deal } from "~/components/post/EditPost";


// NOTE: optimally move this into a separate file
export interface Setting {
  id: string;
  name: string;
  data: any;
}

export const useSettings = () => {
  const { settings, setSettings } = useContext(SettingContext);

  const settingByName = (name: string) => {
    const settingsFiltered = settings && settings.filter(setting => (setting.name == name));
    if(settingsFiltered && settingsFiltered?.length > 0) {
      const setting = settingsFiltered[0];
      return setting;
    } else {
      return null;
    }
  }

  function isInBlacklist(content?: string) {  
    const setting = settingByName("blacklist");
  
    const blacklist: Array<string> = (setting && setting.data) || [];
  
    if(blacklist && blacklist.length > 0) {
      for (const phrase of blacklist) {
        const phraseLC = phrase.toLowerCase();
        if(content && content.toLowerCase().indexOf(phraseLC) !== -1) {
         return phrase;
        }
      }
    }
  
    return null;
  }

  return { settings, settingByName, isInBlacklist, setSettings };
};

interface SettingContext {
  settings: Setting[] | null;
  setSettings: (settings: Setting[] | null) => void;
}

export const SettingContext = createContext<SettingContext>({
  settings: null,
  setSettings: () => {},
});
