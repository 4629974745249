// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/utils/strapiUtils.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/utils/strapiUtils.tsx");
  import.meta.hot.lastModified = "1708335860448.164";
}
// REMIX HMR END

import moment from "moment-timezone";
import { parseHitToCategory } from "./MeiliSearch";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone-esm";
import { addImage, deleteImage } from "./fetchUtils";
import { Box, Center, HStack, IconButton, Image, Input, InputGroup, InputLeftAddon, InputRightAddon, Spinner, VStack, useColorModeValue } from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { useAuth } from "./userUtils";
export function objectToQueryString(obj, prefix = "") {
  const parts = [];
  for (let [key, value] of Object.entries(obj)) {
    const currentPrefix = prefix ? `${prefix}[${key}]` : key;
    if (typeof value === "object" && value !== null) {
      // For nested objects, call the function recursively
      parts.push(objectToQueryString(value, currentPrefix));
    } else {
      // For primitive values, add them to the result array
      const param = `${currentPrefix}=${encodeURIComponent(String(value))}`;
      parts.push(param);
    }
  }
  return parts.join("&");
}
export function parseToDeal(post) {
  const deal = {
    ...post.data.attributes,
    id: String(post.data.id)
  };
  deal.dealDate = deal?.dealDate ? moment(deal.dealDate).format("YYYY-MM-DDTHH:mm") : "";
  deal.expirationDate = deal?.expirationDate ? moment(deal.expirationDate).format("YYYY-MM-DDTHH:mm") : "";
  if (post.data.attributes?.editor?.data && typeof post.data.attributes.editor == "object") {
    // console.log("post.data.attributes.editor", post.data.attributes.editor.data)

    deal.editor_id = post.data.attributes.editor.data.id;
    deal.editor_profilePicture = post.data.attributes.editor.data.attributes.profilePicture?.data?.attributes?.url;
    deal.editor_profilePictureThumb = post.data.attributes.editor.data.attributes.profilePicture?.data?.attributes?.formats?.thumbnail?.url;
    deal.editor = {
      id: deal.editor_id,
      ...post.data.attributes.editor.data.attributes
    };
    deal.editor_username = post.data.attributes.editor.data.attributes.username;
  }
  deal.titleImageThumb = post.data.attributes.titleImage?.data?.attributes?.formats?.thumbnail?.url || "";
  deal.titleImage = post.data.attributes.titleImage?.data?.attributes?.url || "";
  deal.titleImageID = post.data.attributes.titleImage?.data?.attributes ? post.data.attributes.titleImage.data.id : "";

  // console.log("parseDeal", deal.categories);
  if (post.data.attributes?.categories?.data && Array.isArray(post.data.attributes.categories.data)) {
    deal.categories = post.data.attributes.categories.data.map(element => {
      const cat = element.attributes;
      cat.id = element.id;
      return parseHitToCategory(cat);
    });
  }

  // console.log("parseDeal", deal);
  return deal;
}
export function sanitize(values) {
  const sanitized = {
    ...values
  };
  for (const key in sanitized) {
    if (sanitized[key] === undefined || sanitized[key] === "") {
      sanitized[key] = null;
    }
  }
  return sanitized;
}
export function deSanitize(values) {
  const sanitized = {
    ...values
  };
  for (const key in sanitized) {
    if (sanitized[key] === undefined || sanitized[key] === null) {
      sanitized[key] = "";
    }
  }
  // console.log("deSanitizeDeal", sanitized);
  return sanitized;
}
export function dataURItoURL(dataURI) {
  const byteString = window.atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([intArray], {
    type: mimeString
  });
  const url = URL.createObjectURL(blob);
  return url;
}
export const DropZone = props => {
  _s();
  const {
    setImage,
    setNewImageLocal,
    field,
    image,
    uploadByLink = true,
    path = "deal",
    replace = false
  } = props;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState(image?.url);
  const [currentImage, setCurrentImage] = useState(image);
  const [externUrl, setExternUrl] = useState("");
  const {
    user
  } = useAuth();
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      "image/*": []
    },
    onDrop: acceptedFiles => {
      acceptedFiles.map(file => uploadImage(URL.createObjectURL(file)));
      setImage && setLoading(true);
    }
  });
  async function uploadImage(imageURL) {
    if (setImage) {
      await addImage(imageURL, undefined, undefined, {
        field,
        source: externUrl
      }, path).then(async newImage => {
        // console.log(newImage);

        if (replace && currentImage?.id) {
          await deleteImage(currentImage.id);
        }
        setImage(newImage, field);
        setLoading(false);
        setCurrentImage(newImage);
      });
    }
    setNewImageLocal && setNewImageLocal(imageURL);
  }
  async function uploadImageByURL(url) {
    if (url) {
      if (url.startsWith("http")) {
        setLoading(true);
        fetch("/api/fetch-image?" + new URLSearchParams([["url", url]]), {
          headers: {
            Authorization: `Bearer ${user && user.authToken}`,
            "Content-Type": `application/json`
          }
        }).then(response => response.json()).then(async entry => {
          console.log(entry);
          const blobURL = dataURItoURL(entry.dataImage);
          if (setImage) {
            await uploadImage(blobURL);
          } else if (setNewImageLocal) {
            setNewImageLocal(blobURL);
          }
          // props.setTitleImage(blobURL);
          setLoading(false);
        });
      }
    }
  }
  useEffect(() => {
    setCurrentImageSrc(currentImage?.url);
  }, [currentImage]);
  async function deleteCurrentImage(e) {
    e && e.stopPropagation();
    setImage && (await deleteImage(currentImage.id).then(result => {
      // console.log(newImage);
      setImage(null, field);
      setCurrentImage(undefined);
      setLoading(false);
    }));
  }
  return <VStack border={"dashed 3px gray"} borderRadius="xl" bgColor={useColorModeValue("gray.300", "gray.600")} {...getRootProps({
    className: "dropzone"
  })} minH={"100px"} w={"100%"} h={"100%"} _hover={{
    bgColor: "gray.500"
  }} pos={"relative"} gap={5} p={5}>
      <Center>
        <Image boxSize={"100%"} objectFit="contain" src={currentImageSrc} fallbackSrc="/assets/placeholder-image.jpg" />
      </Center>
      <Center textAlign={"center"}>
        <input {...getInputProps()} />
        <HStack width={"100%"}>
          <Box fontSize={"2xl"}>
            <BsUpload />
          </Box>
          <Box>Dateien hier ablegen oder klicken.</Box>
        </HStack>
      </Center>
      {uploadByLink && <InputGroup pb={4}>
        <InputLeftAddon children="Bild Link" />
        <Input id="externurl-input" type="text" value={externUrl} onChange={e => setExternUrl(e.target.value)} onClick={e => {
        e.stopPropagation();
      }} />
        <InputRightAddon px="0">
          <IconButton icon={<DownloadIcon />} aria-label="Download" borderLeftRadius={0} onClick={e => {
          e.stopPropagation();
          // setCurrentImageSrc(externUrl || "");
          uploadImageByURL(externUrl);
        }} />
        </InputRightAddon>
      </InputGroup>}
      {loading && <Center pos={"absolute"} top={0} right={0} bottom={0} left={0}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>}
      {image?.id && <IconButton zIndex="1" aria-label="Löschen" colorScheme="gray" icon={<DeleteIcon />} top={3} right={3} pos={"absolute"} onClick={e => deleteCurrentImage(e)} />}
    </VStack>;
};
_s(DropZone, "F0blByCuhye0Z7DoivnI3cO/Wao=", false, function () {
  return [useAuth, useDropzone, useColorModeValue];
});
_c = DropZone;
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
var _c;
$RefreshReg$(_c, "DropZone");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;